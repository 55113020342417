
















































































import ModuleConfigSettings from '../components/ModuleConfigSettings.vue'
import ModerationLimitItem from '@/components/ModerationCommandLimits/ModerationLimitItem.vue'
import ModerationLimitList from '@/components/ModerationCommandLimits/ModerationLimitList.vue'
import CommandLimitHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/CommandLimitHandlerModule/CommandLimitHandlerModuleBuilder'
import { FrontendCommandLimit, FrontendCommandLimits } from '@/includes/logic/CommandLimits/types'
import { BaseViewActionType } from '@/includes/types/Enums'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    SwitchInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    ModerationLimitList,
    ModerationLimitItem
  },
})
export default class CommandLimitHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: CommandLimitHandlerModuleBuilder

  @Watch('module.state.limits')
  onLimitsChange(limits: FrontendCommandLimits) {
    this.module.setModelLimits(this.module.frontendLimitsToRaw(limits))
  }

  isModalOpen = false

  action: BaseViewActionType = BaseViewActionType.New

  handleCreateClick() {
    this.action = BaseViewActionType.New
    this.module.state.selectedLimit = this.module.newFrontendLimitModel()

    this.isModalOpen = true
  }

  setLimitToEdit(limit: FrontendCommandLimit) {
    this.action = BaseViewActionType.Edit
    this.module.state.selectedLimit = cloneDeep(limit)

    this.isModalOpen = true
  }

  handleOkClick() {
    if (this.module.state.selectedLimit) {
      if (this.action === BaseViewActionType.New) {
        this.module.addLocalLimit()
      }

      if (this.action === BaseViewActionType.Edit) {
        this.module.updateLocalLimit()
      }

      this.module.state.selectedLimit = null
    }
  }

  mounted() {
    this.module.setFrontendLimits()
  }
}
