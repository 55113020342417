































































































































import { FrontendCommandLimit } from '@/includes/logic/CommandLimits/types'
import { AdminOrAllPermissions, BaseViewActionType } from '@/includes/types/Enums'
import { InputSetups } from '@/mixins/input-setups'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Emit, Mixins, Prop, PropSync, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      StepUnit,
    }
  }
})
export default class ModerationLimitItem extends Mixins(UseFields, InputSetups, SelectOptionsMixin) {
  @VModel() limit!: FrontendCommandLimit

  @PropSync('modalActive') isModalOpen!: boolean

  @Prop() action!: BaseViewActionType

  @Prop() isLimitAllPermission!: (limit:FrontendCommandLimit) => boolean

  @Prop() isWhitelistSelected!: (limit:FrontendCommandLimit) => boolean

  @Emit()
  onOkClick() {
    return null
  }

  @Emit()
  onCancel() {
    return null
  }

  get isSaveButtonDisabled() {
    return !this.intervalDependsOnCount || !this.countDependsOnInterval
  }

  get intervalDependsOnCount() {
    const limit = this.limit

    if (limit) {
      const { interval, count } = limit.value

      if (interval === 0 && count === 0) return true
      return !(interval > 0 && count === 0);
    }

    return false
  }

  get countDependsOnInterval() {
    const limit = this.limit

    if (limit) {
      const { interval, count } = limit.value

      if (interval === 0 && count === 0) return true
      return !(count > 0 && interval === 0);

    }

    return false
  }

  get modalTitle() {
    return this.$t(`modal_action_${ this.action }_title`).toString()
  }
}
