var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"moderation-limit-item modal-overflow-visible",attrs:{"title":_vm.modalTitle,"ok-text":_vm.$t('accept'),"ok-button-props":{
    props: {
      disabled: _vm.isSaveButtonDisabled
    }
  }},on:{"ok":_vm.onOkClick,"cancel":_vm.onCancel},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('select-input',{staticClass:"moderation-limit-item__select-command mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.limit.value,
        'key': 'commands',
        'prefix': 'command_limits_',
        'options': _vm.commandsSelectOption.concat(_vm.sCommandsSelectOption).concat(_vm.gCommandsSelectOptions),
        'multiple': true,
        'selectAll': true,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.limit.value,
        'key': 'permissions',
        'prefix': 'command_limits_',
        'options': _vm.commandPermissionsOptions,
        'clearable': false,
      }
    }}}),(_vm.isLimitAllPermission(_vm.limit))?[_c('black-white-list-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.limit.value,
          'key': 'users',
          'prefix': 'command_limits_',
          'checkedTitle': _vm.$t('command_limits_permissions_white_list'),
          'uncheckedTitle': _vm.$t('command_limits_permissions_black_list'),
          'settingWrapper': {
            'hasFieldButton': true,
            'disabledValue': {
              type: 'Blacklist',
              items: [ ]
            },
            'customiseValue': {
              type: 'Blacklist',
              items: [ ]
            },
          },

        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.limit.value,
          'key': 'count',
          'prefix': 'command_limits_',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'settingWrapper': {
            value: 0,
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: 5
          }

        }
      }}}),(!_vm.intervalDependsOnCount)?_c('a-alert',{attrs:{"type":"error","show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('command_limits_interval_depends_on_count_help_message'))},slot:"message"})]):_vm._e(),_c('time-unit-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.limit.value,
          'key': 'interval',
          'prefix': 'command_limits_',
          'units': [_vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day],
          'min': 0,
        }
      }}}),(!_vm.countDependsOnInterval)?_c('a-alert',{attrs:{"type":"error","show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('command_limits_count_depends_on_interval_help_message'))},slot:"message"})]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }